/* eslint-disable no-cond-assign */
/* eslint-disable @typescript-eslint/no-loop-func */
const HelperSplittext = (data) => {
  const prueba = data.text.split(/<br>|<\/br>|{other_line}/g);
  const tranfortext = [];

  function extraerInterpolaciones(texto, values) {
    const regex = /\{([^}]+)\}/g;
    const matches = [];

    let match;

    while ((match = regex.exec(texto)) !== null) {
      const valuesInter = values.filter((rule) => rule.key === match[1]);

      matches.push(valuesInter[0]);
    }

    return matches;
  }

  prueba.forEach((part) => {
    tranfortext.push({
      ...data,
      text: part,
      values: extraerInterpolaciones(part, data.values),
      class_name: `${data.class_name || ''} line-break`,
    });
  });

  return tranfortext;
};

export default HelperSplittext;
