import React from 'react';
import { bool, oneOf, shape, string } from 'prop-types';

import LabelBuilder from './label-builder';
import HelperSplittext from './utils/helper-split-text';
import { withComponentHandler } from '../../hocs/with-component-handler';

/**
 * @typedef {Object} LabelBuilderProps
 * @property {string} [class_name] - Clases CSS adicionales para el componente.
 * @property {'p'|'span'|'h1'|'h2'|'h3'|'h4'|'li'} [html_tag] - La etiqueta HTML que se usará para renderizar el texto.
 * @property {Object} [styleLabel] - Los estilos aplicados al texto del componente.
 * @property {string} [styleLabel.color] - El color del texto.
 * @property {string} [styleLabel.font_family] - La familia tipográfica del texto.
 * @property {string} [styleLabel.font_size] - El tamaño de la fuente del texto.
 * @property {boolean} [styleLabel.isShop] - Indica si el texto pertenece a una tienda.
 * @property {Object} [styles] - Los estilos en línea aplicados al componente.
 * @property {string} [text] - El texto que se renderizará dentro del componente.
 * @property {string} [type] - El tipo de etiqueta que se renderizará.
 */
const WrapperLabel = (props) => {
  const hasJumpLine = props.text.split(/<br>|<\/br>|{other_line}/g);

  if (hasJumpLine) {
    const dataNew = HelperSplittext(props);

    return dataNew.map((info) => <LabelBuilder {...info} key={info.text} />);
  }

  return <LabelBuilder {...props} />;
};

WrapperLabel.propTypes = {
  class_name: string,
  html_tag: oneOf(['p', 'span', 'h1', 'h2', 'h3', 'h4', 'li']),
  styleLabel: shape({
    color: string,
    font_family: string,
    font_size: string,
    isShop: bool,
  }),
  styles: shape({}),
  text: string,
  type: string,
};

export default withComponentHandler(WrapperLabel, { componentName: 'Wrapper-LabelBuilder' });
